// home screen

import React from 'react';
import styled from 'styled-components';
import PowerBtn from '../components/AllSvgs';
import { NavLink } from 'react-router-dom';

const Power = styled.button`
    position: fixed;
    top: 2rem;
    left: 50%;
    transform: translate(-50%,0);

    background-color:#e9faff;
    padding:0.3rem;
    border-radius: 50%;
    border:1px solid #000000;
    height:2.5rem;
    width:2.5rem;

    align-items:center;
    display:flex;
    justify-content: center;
    z-index:3;

    cursor:pointer;

    &:hover{
        background : rgba(25 , 171 , 203 , 0.5);
        border-color: rgba(25 , 171 , 203 , 1);
        box-shadow: 0px 0px 15px rgba(25 , 171 , 203,0.7);
    }

    &>*:first-child{
        color:inherit;
    }
`

const PowerButton = () => {
  return (
    <Power>
        <NavLink to="/">
            <PowerBtn width={30} height={30} fill='currentColor' />
        </NavLink>
    </Power>
  );
}

export default PowerButton;
