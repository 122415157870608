import React from 'react';
/*import { NavLink } from 'react-router-dom';*/
import { Facebook, Github, LinkedIn, Twitter } from '../components/AllSvgs';
import styled from 'styled-components';
import { darkTheme } from '../components/Themes';
import { motion } from 'framer-motion';

const Icons = styled.div`
    display: flex;
    flex-direction : column;
    align-items: center;
    
    position: fixed;
    bottom:0;
    left: 2rem;

    z-index:3;
    

    &>*:not(:last-child){
        margin: 0.5rem 0;
    }
    @media only screen and (max-width: 50em){
        left: 1rem;
    }
`

const Line = styled(motion.span)`
    width: 2px;
    height: 8rem;
    background-color: ${props=> props.color === 'dark' ? darkTheme.text : darkTheme.body};

    @media only screen and (max-width: 50em){
        background-color: ${props=> props.color === 'dark' ? darkTheme.text : darkTheme.body};
    }
`
const Social = styled.a`

`
const SocialIcons = (props) => {
  return (
    <Icons>
        <motion.div
      initial={{ transform: "scale(0)" }}
      animate={{ transform: "scale(1)" }}
      transition={{ type: 'spring', duration: 1, delay: 1.2 }}
    >
            <Social style={{
          color:
            (props.theme === "dark" || props.clicked) &&
            (props.mobileScreen || props.customScreenCondition)
              ? darkTheme.text
              : darkTheme.body,
        }} target='_blank' href="https://margisoni.com/">
                <LinkedIn width={25} height={25} fill={props.theme === "dark" ? darkTheme.text : darkTheme.body} />
            </Social>
        </motion.div>
        <motion.div
      initial={{ transform: "scale(0)" }}
      animate={{ transform: "scale(1)" }}
      transition={{ type: 'spring', duration: 1, delay: 1.4 }}
    >
            <Social style={{color:'inherit'}} target='_blank' href="https://margisoni.com/">
                <Github width={25} height={25} fill={props.theme === "dark" ? darkTheme.text : darkTheme.body} />
            </Social>
        </motion.div>
        <motion.div
      initial={{ transform: "scale(0)" }}
      animate={{ transform: "scale(1)" }}
      transition={{ type: 'spring', duration: 1, delay: 1.6 }}
    >
            <Social style={{color:'inherit'}} target='_blank' href="https://margisoni.com/">
                <Twitter width={25} height={25} fill={props.theme === "dark" ? darkTheme.text : darkTheme.body} />
            </Social>
        </motion.div>
        <motion.div
      initial={{ transform: "scale(0)" }}
      animate={{ transform: "scale(1)" }}
      transition={{ type: 'spring', duration: 1, delay: 1.8 }}
    >
            <Social style={{color:'inherit'}} target='_blank' href="https://margisoni.com/">
                <Facebook width={25} height={25} fill={props.theme === "dark" ? darkTheme.text : darkTheme.body} />
            </Social>
        </motion.div>

        <Line color={props.theme}
        initial={{
            height:0
        }}
        animate={{
            height:'8rem',
            scale:[0,1,1.5,1]
        }}
        transition={{
            type:'spring', duration:1, delay: 0.8
        }}

        />
    </Icons>
  );
}

export default SocialIcons;
