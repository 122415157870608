import { motion } from 'framer-motion';
import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const Box= styled(motion.li)`
    width: 17rem;
    height: 50vh;
    background-color: ${props => props.theme.text};
    color: ${props => props.theme.body};
    padding: 1.5rem 2rem;
    margin-right: 8rem;
    border-radius: 0 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid ${props => props.theme.body};
    transition: all 0.2s ease;

    &:hover{
        background-color: ${props => props.theme.body};
        color: ${props => props.theme.text};
        border: 1px solid ${props => props.theme.text};
    }

`
const Title = styled.h2`
font-size: calc(1em + 0.5vw);

`

const Description = styled.h2`
font-size: calc(0.8em + 0.3vw);
font-family: 'Karla', sans-serif;
font-weight: 500;
`

const Tags = styled.div`
border-top: 2px solid ${props => props.theme.body};
padding-top: 0.5rem;
display: wrap;
${Box}:hover &{
    border-top: 2px solid ${props => props.theme.text};
}
`

const Tag = styled.span`
margin-right: 1rem;
display: inline-flex;
`
const Footer = styled.footer`
display:flex;
justify-content: space-between;
`
const Link = styled(NavLink)`
background-color: ${props => props.theme.body};
color: ${props => props.theme.text};
text-decoration: none;
padding:0.5rem calc(2rem + 2vw);
border-radius: 0 0 0 50px;
font-size: calc(1rem +0.5vw);

${Box}:hover &{
    color: ${props => props.theme.body};
    background-color: ${props => props.theme.text};
}
`
const Item = {
    hidden : {scale : 0},
    show : {
      scale :1,
      transition:{
        type: 'spring',
        duration: 0.5
      }
    }
  }

const Card = (props) => {
    const {id, name, description, tags, demo} = props.data;
  return (
    <Box key={id}  variants={Item}>
        <Title>{name}</Title>
        <Description>
            {description}
        </Description>
        <Tags>
            {
                tags.map( (t, id) => {
                    return <Tag key={id}>#{t}</Tag>
                }
                )
            }
        </Tags>
        <Footer>
            <Link to={{pathname: '${demo}'}} target="_blank">
                Visit
            </Link>
        </Footer>
    </Box>
  );
}

export default Card;
