import React, { useEffect, useRef } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { darkTheme } from './Themes';
import LogoComponents from '../subComponents/LogoComponents';
import SocialIcons from '../subComponents/SocialIcons';
import PowerButton from '../subComponents/PowerButton';
import PORTFOLIO from "../data/WorkData";
import Card from '../subComponents/Card';
import { YinYang } from './AllSvgs';
import { motion } from 'framer-motion';

const Box = styled.div`
background-color: ${props => props.theme.body};

height: 400vh;
position: relative;
display: flex;
align-items: center; 
`
const Main = styled(motion.ul)`
position: fixed;
top: 12rem;
left: calc(1rem + 10vw);
height: 40vh;
display: flex;

color: white;

`

const Rotate=styled.span`
display: block;
position: fixed;
right: 1rem;
bottom:1rem;
width:80px;
height: 80px;
z-index:1;
`

const BigTitle=styled.h1`
position: fixed;
  top: ${props => props.top};
  left: ${props => props.left};
  right: ${props => props.right};
  color: ${props => `rgba(${props.theme.bodyRgba}, 0.1)`};
  font-size: calc(4rem + 5vw);
  z-index: 10;
`

const container = {
  hidden : {opacity : 0},
  show : {
    opacity :1,
    transition:{
      staggerChildren: 0.5,
      duration: 0.5
    }
  }
}

const Portfolio = () => {
  const ref = useRef(null);
  const yinyang = useRef(null);

  useEffect(() => {
    let element = ref.current;

    const rotate = () =>{
      element.style.transform = `translateX(${-window.pageYOffset}px)`

      yinyang.current.style.transform = `rotate(` + -window.pageYOffset + 'deg)'
    }

    window.addEventListener('scroll', rotate)

    return() => window.removeEventListener('scroll', rotate)
  }, []);

  return (
    <ThemeProvider theme={darkTheme}>
        <Box>
            <LogoComponents  theme='dark' />
            <SocialIcons theme='dark' />
            <PowerButton/>
            <Main ref={ref} variants={container} initial='hidden' animate= 'show'>
              {
                PORTFOLIO.map( d =>
                  <Card key={d.id} data={d} />

                )
              }
            </Main>
            <Rotate ref={yinyang}>
                <YinYang width={80} height={80} fill={darkTheme.text} />
            </Rotate>
            <BigTitle top='10%' right="10%"> WORK </BigTitle>
        </Box>
    </ThemeProvider>
    
  );
}

export default Portfolio;
