import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import {  lightTheme } from './Themes';
import { Design, Develope } from './AllSvgs';
import LogoComponents from '../subComponents/LogoComponents';
import SocialIcons from '../subComponents/SocialIcons';
import PowerButton from '../subComponents/PowerButton';
import ParticleComponent from '../subComponents/ParticleComponent';


const Box = styled.div`
background-color: ${props => props.theme.body};
width: 100vw;
height: 100vh;
position: relative;
display: flex;
justify-content: space-evenly; 
align-item: center;

@media (max-width: 50em){
    flex-direction: column;
    padding: 8rem 0px;
    height: auto;
    align-items: center;
}

`

const Main = styled.div`
border: 2px solid #d6f2fa;
color: ${props => props.theme.text};
background-color: ${props => props.theme.body};
padding: 2rem;
width: 30vw;
height: 60vh;
z-index: 3;
line-height: 1.5;
margin-top: calc(5rem + 2vw);
cursor: pointer;
font-family: 'Ubuntu Mono', monospace;
display: flex;
flex-direction: column;
justify-content: space-between;
box-shadow: 9px 6px 20px #bcdee8;
transition: all 1s ease;

&>*:first-child{
    margin-right: 1rem;
}
border-radius: 70px 0px;

&:hover{
    color:${props => props.theme.body};
    background-color: ${props => props.theme.text};
    border-radius: 0px 70px;
}

@media (max-width: 50em){
    width: 50vw;
    height: max-content;
    margin-top:2rem;
}

@media (max-width: 60em){
    height: 55vh;
    
}




`

const BigTitle=styled.h1`
position: fixed;
  left: ${props => props.left};
  bottom: ${props => props.bottom};
  color: ${props => `rgba(${props.theme.textRgba}, 0.1)`};
  font-size: calc(4rem + 5vw);
  z-index: 0;
  line-height:0.8;
`

const Title = styled.h2`
display: flex;
justify-content: center;
align-items: center;
font-size: calc(1em + 1vw);

${Main}:hover &{
    &>*{
        fill: ${props => props.theme.body};
    }
}

&>*:first-child{
    margin-right: 1rem;
}
`
const Description = styled.div`
color: ${props => props.theme.text};
font-sie: calc(0.6em + 1vw);
padding: 0.5rem 0;

${Main}:hover &{
        color: ${props => props.theme.body};
}

strong{
    margin-bottom: 1rem;
    text-transform: uppercase;
}

ul,p{
    margin-left: 2rem;
}

`

const Skills = () => {
  return (
    <ThemeProvider theme={lightTheme}>
        <Box>
            <LogoComponents  theme='light' />
            <SocialIcons theme='light' />
            <PowerButton theme='light' />
            <ParticleComponent theme='light' />
            <Main>
                <Title>
                    <Design width={40} height={40} /> Designer
                </Title>
                <Description>
                    I love to create design which speaks, Keep it clean, minimal and simple.
                </Description>
                <Description>
                    <strong>I like to Design</strong>
                    <ul>
                        <li>Web Design</li>
                        <li>Mobile Apps</li>
                    </ul>
                </Description>
                <Description>
                    <strong>Tools</strong>
                    <ul>
                        <li>Figma, Adobe XD</li>
                        <li>Adobe Photoshop</li>
                        <li>corelDraw</li>
                    </ul>
                </Description>
            </Main>
            <Main>
                <Title>
                    <Develope width={40} height={40} /> Developer
                </Title>
                <Description>
                    I value business or brand for which i'm creating, thus i enjoy bringing new ideas to life.
                </Description>
                <Description>
                    <strong>SKILLS</strong>
                    <p>Html, Css, Js, React, Redux, Sass, Bootstrap, Tailwind, NextJS, MySQL, MongoDB, NodeJS etc.</p>
                </Description>
                <Description>
                    <strong>Tools</strong>
                    <p>VScode, Github, Jira, JMeter etc.</p>
                </Description>
            </Main>
            <BigTitle bottom='0' left="38%"> SKILLS </BigTitle>
        </Box>
    </ThemeProvider>
    
    
  );
}

export default Skills;
