import React from 'react';
import styled from 'styled-components';
import { darkTheme } from '../components/Themes';
import { NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';


const Logo= styled(motion.h1)`
    display:inline-block; 
    color: ${props => props.color === 'dark' ? darkTheme.text : darkTheme.body};
    font-family: 'Pacifico' , cursive;

    position: fixed;
    left: 2rem;
    top: 2rem;
    z-index: 3;
`

const LogoComponents = (props) => {
  return (
    <NavLink to="/">
    <Logo color={props.theme}>
      MS
    </Logo>
    </NavLink>
  );
}

export default LogoComponents;
