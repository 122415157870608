/* For Dark and light theme variables */

export const lightTheme ={
    body:"#e9faff",
    // body:"#FCF6F4",
    text:"#000000",
    fontFamily:"'Source Sans Pro', sans-serif",
    bodyRgba : "252, 246, 244",
    textRgba:"0,0,0",
}

export const darkTheme = {
    body:"#000000",
    text:"#e9faff",
    fontFamily:"'Source Sans Pro', sans-serif",
    bodyRgba : "252, 246, 244",
    textRgba:"0,0,0",
}