import {motion} from 'framer-motion';
import React, {useState} from 'react';
import styled, { keyframes } from 'styled-components';
import PowerButton from '../subComponents/PowerButton';
import LogoComponents from '../subComponents/LogoComponents';
import SocialIcons from '../subComponents/SocialIcons';
import { NavLink } from 'react-router-dom';
import { YinYang } from './AllSvgs';
import Introduction from './Introduction';




const MainContainer = styled.div`
  background: ${props => props.theme.body};
  width:100vw;
  height: 100vh;
  overflow: hidden;
  position:relative;

  h2,h3,h4,h5,h6{
    font-family: 'Karla', sans-serif;
    font-weight:500;
  }
`

const Container = styled.div`
  padding:2rem;
`

const Contact = styled.a`

color: ${props => props.theme.text};
position: absolute;
top:2rem;
right: calc(1rem + 2vw);
text-decoration: none;  
z-index:1;

@media only screen and (max-width: 50em){
color: ${props => props.click ? props.theme.body : props.theme.text};
}

`
const Work = styled(NavLink)`
color: ${props => props.theme.text};
position: absolute;
top: 50%;
right: calc(1rem + 2vw);
transform: rotate(90deg) translate(-50%, -50%);
text-decoration: none;
z-index:1;

@media only screen and (max-width: 50em){
  color: ${props => props.click ? props.theme.body : props.theme.text};
  text-shadow: ${props => props.click ? 'rgb(0, 0, 0) 0px 0px 4px;' : 'none'};
  right: 0;
  top: 54%;
  }
@media (max-width: 40em){
  font-size: 1.2em;
}
@media (max-width: 30em){
  font-size: 1em;
}
`
const SKILLS = styled(NavLink)`
color: ${props => props.click ? props.theme.body : props.theme.text};
position: absolute;
top: 50%;
left: calc(1rem + 2vw);
transform: translate(-50%, -50%) rotate(-90deg);
text-decoration: none;
z-index:1;

@media only screen and (max-width: 50em){

  text-shadow: ${props => props.click ? 'rgb(0, 0, 0) 0px 0px 4px;' : 'none'};

  }
  @media (max-width: 40em){
    font-size: 1.2em;
  }
  @media (max-width: 30em){
    font-size: 1em;
  }


`

const BottomBar = styled.div`
position: absolute;
bottom: 1rem;
left: 0;
right: 0;
width:100%;
display: flex;
justify-content: space-evenly;
`

const ABOUT = styled(NavLink)`
color: ${props => props.click ? props.theme.body : props.theme.text};
text-decoration: none;
z-index:1;

@media only screen and (max-width: 50em){
  color: ${props =>  props.theme.text};
  }
  @media (max-width: 40em){
    font-size: 1.2em;
  }
  @media (max-width: 30em){
    font-size: 1em;
  }
`
const EXPERIENCE = styled(NavLink)`
color: ${props => props.theme.text};
text-decoration: none;
z-index:1;
@media only screen and (max-width: 50em){
  color: ${props => props.click ? props.theme.body : props.theme.text};
  }
@media (max-width: 40em){
  font-size: 1.2em;
}
@media (max-width: 30em){
  font-size: 1em;
}

`
const rotate = keyframes`
from{
  transform: rotate(0);
}
to{
  transform: rotate(360deg);
}
`
const Center = styled(motion.button)`
position: absolute;
top: ${props => props.click ? '85%' : '50%'};
left: ${props => props.click ? '92%' : '50%'};
transform: translate(-50%, -50%);
border:none;
outline: none;
background-color: transparent;
cursor: pointer;

display:flex;
flex-direction: column;
justify-content: center;
align-items: center;
transition: all 1s ease;

&>:first-child{
  animation: ${rotate} infinite 1.5s linear;
}

&>:last-child{
  display:${props => props.click ? 'none' : 'inline-block'};
  padding-top:1rem;
}



@media only screen and (max-width: 50em){
  top: ${props => props.click ? '90%' : '50%'};
left: ${props => props.click ? '90%' : '50%'};

    width: 120px;
    height: 120px;
}
@media only screen and (max-width: 30em){
  width: 80px;
  height: 80px;
}
`
const DarkDiv = styled.div`
position: absolute;
top:0;
background-color: #000000;
bottom:0;
right:50%;
width: ${props => props.click ? '50%' : '0'};
height:${props => props.click ? '100%' : '0'};
z-index: 1;
transition: height 0.5s ease, width 1s ease 0.5s;

@media (max-width: 50em){
    width: ${props => props.click ? '100%' : '0'};
height:${props => props.click ? '50%' : '0'};
    right: 0px;
    transition: width 0.5s ease 0s, height 1s ease 0.5s;
}

`

const Main = () => {
  const [click, setclick] = useState(false);
  const handleClick = () => setclick(!click);
  const isSmallScreen = window.innerWidth <= 768;
  return (
    <MainContainer>
      <Container>
        <PowerButton />
        <LogoComponents theme = {click ? 'dark' : 'light'} />
        <SocialIcons theme= {click ? 'dark' : 'light'} />
        {isSmallScreen ? (
        <SocialIcons theme= {'light'} />
      ) : (
        
        <SocialIcons theme= {click ? 'dark' : 'light'} />
      )}

        <DarkDiv click={click} />
        <Center click={click}
        initial = {{opacity: 0}}
        animate = {{opacity:1}}
        transition={{ type: 'easeIn', duration: 3, delay:1 }}

        >
          <YinYang onClick={() => handleClick()} width={click ? 120 : 200} height={click ? 120 : 200} fill='currentColor' />
          <span>Click Me</span>
        </Center>

        <Contact target="_blank" href="mailto:margi2804@yahoo.com" click={click}>
            <motion.h2
            initial={{
              y:-200,
              transition: {type : 'spring', duration: 1, delay: 1 }
            }}
            animate={{
              y:0,
              transition: {type : 'spring', duration: 1, delay: 1 }
            }}
            whileHover={{scale:1.1}}
            whileTap={{scale:0.9}}
            >Say Hi...</motion.h2>
        </Contact>
        <Work to="/Portfolio" click={click}>
            <motion.h2
            initial={{
              y:-200,
              transition: {type : 'spring', duration: 1, delay: 1 }
            }}
            animate={{
              y:0,
              transition: {type : 'spring', duration: 1, delay: 1 }
            }}
            whileHover={{scale:1.1}}
            whileTap={{scale:0.9}}
            >Portfolio</motion.h2>
        </Work>
        <SKILLS to="/Skills" click={click}>
            <motion.h2
            initial={{
              y:-200,
              transition: {type : 'spring', duration: 1, delay: 1 }
            }}
            animate={{
              y:0,
              transition: {type : 'spring', duration: 1, delay: 1 }
            }}
            whileHover={{scale:1.1}}
            whileTap={{scale:0.9}}
            >Skills</motion.h2>
        </SKILLS>
        <BottomBar>
          <ABOUT to="/About" click={click}>
              <motion.h2
              initial={{
                y:200,
                transition: {type : 'spring', duration: 1, delay: 1 }
              }}
              animate={{
                y:0,
                transition: {type : 'spring', duration: 1, delay: 1 }
              }}
              whileHover={{scale:1.1}}
              whileTap={{scale:0.9}}
              >About Me</motion.h2>
          </ABOUT>
          <EXPERIENCE to="/Experience">
            <motion.h2
            initial={{
              y:200,
              transition: {type : 'spring', duration: 1, delay: 1 }
            }}
            animate={{
              y:0,
              transition: {type : 'spring', duration: 1, delay: 1 }
            }}
              whileHover={{scale:1.1}}
              whileTap={{scale:0.9}}
              >My Experience</motion.h2>
          </EXPERIENCE>
          

        </BottomBar>

      </Container>
      {click ? <Introduction /> :  null}
    </MainContainer>
  );
}

export default Main;