import React from 'react';
import styled, { ThemeProvider, keyframes } from 'styled-components';
import { darkTheme } from './Themes';
import LogoComponents from '../subComponents/LogoComponents';
import SocialIcons from '../subComponents/SocialIcons';
import PowerButton from '../subComponents/PowerButton';
import ParticleComponent from '../subComponents/ParticleComponent';

import astronaut from '../assets/Images/spaceman.png';


const Box = styled.div`
background-color: ${props => props.theme.body};
width: 100vw;
height: 100vh;
position: relative;
overflow: hidden;
`

const float = keyframes`
0% {transform: translateY(-10px)}
50% {transform: translateY(15px) translateX(15px)}
100% {transform: translateY(-10px)}
`

const Spaceman= styled.div`
position: absolute;
top: 10%;
right: 5%;
width: 20vw;
animation : ${float} 4s ease infinite;
img{
    width:100%;
    height: auto;
}

@media only screen and (max-width: 50em){
  width: 30vw;
  top: 5%;
}
`

const Main= styled.div`
border: 2px solid ${props => props.theme.text};
color: ${props => props.theme.text};
padding: 2rem;
width: 50vw;
height: 60vh;
z-index: 3;
line-height:1.5;
display: flex;
justify-content: center;
align-items: center;
font-size:calc(0.4rem + 0.9vw);
backdrop-filter: blur(4px);

position: absolute;
left: calc(5rem + 5vw);
top:10rem;
font-family: 'Ubuntu Mono',monospace;
font-style: italic;

@media only screen and (max-width: 50em){
  font-size:calc(0.6rem + 1vw);
  width: 60vw;
  left: calc(3.5rem + 5vw);
  padding:1.5rem;
}

`
const BigTitle=styled.h1`
position: fixed;
  top: ${props => props.top};
  left: ${props => props.left};
  right: ${props => props.right};
  color: ${props => `rgba(${props.theme.bodyRgba}, 0.1)`};
  font-size: calc(4rem + 5vw);
  z-index: 0;
`


const About = () => {
  return (
    <ThemeProvider theme={darkTheme}>
        <Box>
            <LogoComponents  theme='dark' />
            <SocialIcons theme='dark' />
            <PowerButton/>
            <ParticleComponent theme='dark' />
            
            <Spaceman>
                <img src={astronaut} alt="spaceman" />
            </Spaceman>
            <Main>
                I'm a front-end developer having 5+ years of experience creating simple yet beautiful websites with great user experience. I always looking for new and innovative ways to improve and expand my skills, and like to stay up-to-date with the latest trends in the field. 
                <br /><br />
                I take great pride in my work and am committed to delivering high-quality results that exceed my clients' expectations.My approach is collaborative and client-focused, and I strive to build long-term relationships with those I work with.
                <br /><br />
                When I am not working, I enjoy cooking, dancing and gaming. I believe that it is crucial to keep a healthy work-life balance, and I find that pursuing my interests outside of work helps me to recharge and stay focused.
            </Main>
            <BigTitle top='5rem' left="5rem"> ABOUT </BigTitle>
        </Box>
    </ThemeProvider>
    
  );
}

export default About;
