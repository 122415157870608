const PORTFOLIO = [
    {
        id:1,
        name:"Bjob - Employee Registration Page",
        description:"It includes various registration page features like register through social media or register with contact number or full registration where you can enter all the personal details as well as can select multiple checkbox.",
        tags:["PHP","API","JavaScript","MySQL","jQuery","Bootstrap", "jQuery"],
        
        demo:"https://bjob.ca/",
        github:"https://bjob.ca/"
    },
    {
        id:2,
        name:"MyJobBook - Admin Panel",
        description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
        tags:["react","sass"],
        
        demo:"https://myjobbook.ca/",
        github:"https://myjobbook.ca/"
        
    },
    {
        id:3,
        name:"HexCue",
        description:" Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
        tags:["bootstrap","JavaScript","jQuery"],
        
        demo:"https://hexcue.com",
        github:"https://hexcue.com"
    },
    {
        id:4,
        name:"Montrose Pharmacy",
        description:" Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
        tags:["react","styledComponents","react-router"],
        
        demo:"https://montrosepharmacy.ca",
        github:"https://montrosepharmacy.ca"
       
    },{
        id:5,
        name:"CRMovers",
        description:" Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
        tags:["react","redux","framer-motion"],
        
        demo:"https://crmovers.ca",
        github:"https://crmovers.ca"
        
    },
    {
        id:6,
        name:"East Side Cleaners",
        description:" Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
        tags:["react","react-router","css"],
        
        demo:"https://eastsidecleaners.ca",
        github:"https://eastsidecleaners.ca"
        
    },
    {
        id:7,
        name:"LeatherTechs",
        description:" Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
        tags:["react","react-router","css"],
        
        demo:"https://leathertechs.com.au",
        github:"https://leathertechs.com.au"
        
    },
  
] 
 
export default PORTFOLIO;
 

