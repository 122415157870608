import React from 'react';

const ContactMe = () => {
  return (
    <div>
        ContactMe Component/Page
    </div>
  );
}

export default ContactMe;
