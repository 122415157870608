import { ThemeProvider } from "styled-components"
import GlobalStyle from "./globalStyles"
import { lightTheme } from "./components/Themes"
import { Routes, Route, useLocation } from 'react-router-dom';

// Components
import Main from "./components/Main"
import About from "./components/About"
import Experience from "./components/Experience"
import Skills from "./components/Skills"
import Portfolio from "./components/Portfolio"
import ContactMe from "./components/ContactMe"
import { AnimatePresence } from "framer-motion";
import { useEffect } from "react";

function App() {
const location = useLocation();
useEffect(() => {
  document.title = 'Margi Soni';
  const metaTag = document.createElement('meta');
    metaTag.name = 'description';
    metaTag.content = ' Welcome to Margi\'s website, where youll find a world of different technologies and skills. Explore my skills, portfolio to uncover the future opportunities that cater to your needs. Let\'s get connect!!!';
    document.head.appendChild(metaTag);

    return () => {
      document.head.removeChild(metaTag);
    };
    
}, []);
  return <>

    <GlobalStyle />

      <ThemeProvider theme={lightTheme}>
      <AnimatePresence exit={{ opacity: 0 }} transition={{ duration: 0.2 }} mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route exact path="/" Component={Main}/>
          <Route exact path="/about" Component={About}/>
          <Route exact path="/experience" Component={Experience}/>
          <Route exact path="/portfolio" Component={Portfolio}/>
          <Route exact path="/skills" Component={Skills}/>
          <Route exact path="/contact" Component={ContactMe}/>
        </Routes>

        </AnimatePresence>
        
      </ThemeProvider>


    
    </>
    
}

export default App

/*
import { Routes, Route } from 'react-router-dom';
import Main from "./components/Main"
import AboutPage from "./components/AboutPage"
import Experience from "./components/Experience"

const App = () => {
 return (
    <>
       <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/experience" element={<Experience />} />
       </Routes>
    </>
 );
}; 

export default App;*/