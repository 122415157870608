import React from 'react';

const Experience = () => {
  return (
    <div>
        Experience Component/Page
    </div>
  );
}

export default Experience;
