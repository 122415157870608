import React from 'react';
import styled from 'styled-components';
import {motion} from 'framer-motion';
import Me from '../assets/Images/profile-img.png'


const Box = styled(motion.div)`
position: absolute;
left:50%;
top:50%;
transform: translate(-50%, -50%);
width:65vw;
height: 55vh;
display: flex;


background: linear-gradient(
    to right,
    ${props => props.theme.body} 50%,
    ${props => props.theme.text} 50%) bottom,
    linear-gradient(
        to right,
        ${props => props.theme.body} 50%,
        ${props => props.theme.text} 50%) top;
    
    z-index: 1;
    background-repeat: no-repeat;
background-size: 100% 2px;
    border-left : 2px solid ${props => props.theme.body};
    border-right : 2px solid ${props => props.theme.text};




    @media only screen and (max-width: 50em){
        background: 
    linear-gradient(
      to bottom,
      ${props => props.theme.body} 50%,
      ${props => props.theme.text} 50%) left,
    linear-gradient(
      to bottom,
      ${props => props.theme.body} 50%,
      ${props => props.theme.text} 50%) right;

            z-index: 1;
            background-repeat: no-repeat;
background-size: 2px 100%;

    border-top : 2px solid ${props => props.theme.body};
    border-bottom : 2px solid ${props => props.theme.text};
    border-left: 0px;
    border-right: 0;
}
@media (max-width: 30em){
    width: 70vw;
}
@media (max-width: 40em){
    width: 60vw;
}

`


const SubBox = styled.div`
width: 50%;
position: relative;
display: flex;

.pic{
    position: absolute;
    bottom:0;
    left:50%;
    transform: translate(-50%, 0%);
    width:100%;
    height: auto;

    @media (max-width: 50em){
        width: 70%;
    }

    @media (max-width: 40em){
        width: 80%;
    }

    @media (max-width: 30em){
        width: 90%;
    }
}

@media (max-width: 50em){
    width: 100%;
    height: 50%;
    display: contents;
}
`

const Text = styled.div`
font-size: calc(1em + 1.5vw);
color: ${props => props.theme.body};
padding:2rem;
cursor: pointer;
display:flex;
flex-direction: column;
justify-content: evenly;

&>*: last-child{
    color: ${props => `rgba(${props.theme.bodyRgba},0.6)`};
    font-size: calc(0.5rem + 1.5vw);
    font-weight: 300; 
}

`


const Introduction = () => {
  return (
    <Box
    initial = {{height:0}}
    animate = {{height:'55vh'}}
    transition={{type: 'spring', duration: 2, delay: 1}}
    >
        <SubBox>
            <Text>
                
                <h3>
                Hello and welcome to my website!
                </h3>
                <h6>My name is Margi Soni, and I'm thrilled to have you here</h6>
            </Text>
        </SubBox>
        <SubBox>
            <motion.div
            initial = {{opacity:0}}
            animate = {{opacity: 1}}
            transition={{duration: 1, delay: 2}}>
                <img className='pic' src={Me} alt="profile pic" />
            </motion.div>
        </SubBox>
    </Box>
  );
}

export default Introduction;
